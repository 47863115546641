import React from 'react'

import { useNavigate } from 'react-router-dom';
import Error from './../Assets/Images/404.svg' 
import './notfound.scss'

function NotFound() {
    const navigate = useNavigate();
        return (
            <>
            <div className='not_found'>
                <img src={Error} alt="Not Found Any Web Page in Vidhan Infotech" title="Not Found Any Web Page in Vidhan Infotech" />
                <div className="error_text">Page Not Fount</div>
                <button className='goback' onClick={() => navigate(-1)}>go back</button>
            </div>
        </>
    )
}
export default React.memo(NotFound)