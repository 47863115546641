import React, { useEffect, useState } from "react";
import "./App";
import { Routes, Route, useLocation } from "react-router-dom";
import Home from "./Pages/Home/Home";
import About from "./Pages/About/About";
import Services from "./Pages/Services/Services";
// import ViewServices from "./Pages/Services/ViewServices";
import Portfolio from "./Pages/Portfolio/Portfolio";
import Career from "./Pages/Career/Career";
import NotFound from "./Components/NotFound";
import Blog from "./Pages/Blog/Blog";
import Viewblogs from "./Pages/Blog/Viewblogs";
import topArrow from "./Assets/Red_Theme/topArrow.png"

function App() {
  const [isVisible, setIsVisible] = useState(false);
  const { pathname } = useLocation();

  useEffect(() => {
    function handleWindowResize() {
      localStorage.setItem("windowWidth", window.innerWidth);
      localStorage.setItem("windowHeight", window.innerHeight);
    }

    handleWindowResize();
    window.addEventListener("resize", handleWindowResize);
    return () => {
      window.removeEventListener("resize", handleWindowResize);
    };
  }, []);

  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
    return () => {
      window.removeEventListener("scroll", toggleVisibility);
    };
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0); // Scroll to the top on route change
  }, [pathname]);

  return (
    <>
      <React.Fragment>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/about/:id" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/portfolio" element={<Portfolio />} />
          <Route path="/career" element={<Career />} />
          <Route path="/blog" element={<Blog />} />
          <Route path="/blog/:id" element={<Viewblogs />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </React.Fragment>

      <div className="scroll-to-top">
        {isVisible && (
          <button onClick={scrollToTop} className="scroll-button">
            <img src={topArrow} className="img-fluid" alt="Scroll to top" />
          </button>
        )}
      </div>
    </>
  );
}
export default React.memo(App);