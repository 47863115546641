import React, { useState } from "react";
import NavigationBar from "../Shared/NavigationBar";
import NewFooter from "../Shared/NewFooter";
import "./Portfolio.scss";
import PortfolioBox from "../../Components/PortfolioBox";
import Uiux from "./Uiux";

function Portfolio() {
  const gallerydata = [
    {
      id: 1,
      image: require("../../Assets/Images/Portfolio/React/barber-app.webp"),
      alt: "Vidhan Infotech Barber App",
      cap: "Vidhan Infotech Barber App",
      name: "Barber App",
      technology: "React Js  + Laravel",
      category: "react",
      link: "http://barberapp.vidhaninfotech.com",
    },
    {
      id: 2,
      image: require("../../Assets/Images/Portfolio/React/arcade.webp"),
      alt: "Vidhan Infotech Arcade Site",
      cap: "Vidhan Infotech Arcade Site",
      name: "Arcade",
      technology: "React Js",
      category: "react",
      link: "http://arcade.vidhaninfotech.com/",
    },
    {
      id: 3,
      image: require("../../Assets/Images/Portfolio/ReactNative/online-food-service-img.webp"),
      alt: "Vidhan Infotech Food Ordering App",
      cap: "Vidhan Infotech Food Ordering App",
      name: "Food Ordering App",
      technology: "React Native",
      category: "mobile",
      link: "https://play.google.com/store/apps/details?id=order.rashays.app",
    },
    {
      id: 4,
      image: require("../../Assets/Images/Portfolio/React/nautical.webp"),
      alt: "Vidhan Infotech Nautical Narwhals",
      cap: "Vidhan Infotech Nautical Narwhals",
      class: "wr_50",
      name: "Nautical Narwhals",
      technology: "React Js",
      category: "react",
      link: "http://smartcontract.vidhaninfotech.com/",
    },
    // { id: 5, image: require('../../Assets/Images/Portfolio/React/green_ngo.webp'), name: "Green Ngo", technology: "React Js", category: "react", link: "https://growbeta.greenhimalayas.org" },
    // { id: 6, image: require('../../Assets/Images/Portfolio/React/eno.webp'), name: "Eno", technology: "React Js", category: "react", link: "https://eno-app.azurewebsites.net" },
    // { id: 7, image: require('../../Assets/Images/Portfolio/Angular/syncedss.webp'), class: "wl_50", name: "Synced", technology: "Angular", category: "angular", link: "http://synced.vidhaninfotech.com" },
    // { id: 7, image: require('../../Assets/Images/Portfolio/Angular/CSM-scaled.webp'), name: "Customer Service Module", technology: "Angular", category: "angular", link: "skype:live:.cid.8f1bea8854ffd1c?chat" },
    // { id: 8, image: require('../../Assets/Images/Portfolio/Angular/Catipult.webp'), name: "Catipult", technology: "Angular", category: "angular", link: "https://www.catipult.ai/" },
    {
      id: 9,
      image: require("../../Assets/Images/Portfolio/Net/tekvision.webp"),
      alt: "Vidhan Infotech Tek Visions",
      cap: "Vidhan Infotech Tek Visions",
      name: "Tek Visions",
      technology: ".Net",
      category: "net",
      link: "https://teksolution.vidhaninfotech.com/",
    },
    // { id: 10, image: require('../../Assets/Images/Portfolio/Net/swanscoring.webp'), name: "Swan Scoring", technology: ".Net", category: "net", link: "https://swanscoring.com/" },
    // { id: 11, image: require('../../Assets/Images/Portfolio/Net/syncedss.webp'), name: "Synced", technology: ".Net Core + Angular", category: "net", link: "http://synced.vidhaninfotech.com" },
    // { id: 11, image: require('../../Assets/Images/Portfolio/Net/pda-img.webp'), class: "wr_50", name: "PDA Store", technology: ".Net", category: "net", link: "skype:live:.cid.8f1bea8854ffd1c?chat" },
    {
      id: 12,
      image: require("../../Assets/Images/Portfolio/Net/online-restuarant-img-scaled.webp"),
      alt: "Vidhan Infotech Online Restuarant Management Sytem",
      cap: "Vidhan Infotech Online Restuarant Management Sytem",
      name: "Online Restuarant Management Sytem",
      technology: ".Net",
      category: "net",
      link: "skype:live:.cid.8f1bea8854ffd1c?chat",
    },
    {
      id: 13,
      image: require("../../Assets/Images/Portfolio/Net/emp_system-img.webp"),
      alt: "Vidhan Infotech Employee Monitoring System",
      cap: "Vidhan Infotech Employee Monitoring System",
      name: "Employee Monitoring System",
      technology: ".Net",
      category: "net",
      link: "http://myconnect.vidhaninfotech.com/",
    },
    {
      id: 14,
      image: require("../../Assets/Images/Portfolio/Net/Imsportfolio-min-new-scaled-1.webp"),
      alt: "Vidhan Infotech Online Education management system",
      cap: "Vidhan Infotech Online Education management system",
      name: "Online Education management system",
      technology: ".Net",
      category: "net",
      link: "skype:live:.cid.8f1bea8854ffd1c?chat",
    },
    // { id: 14, image: require('../../Assets/Images/Portfolio/Net/green.webp'), name: "Green Onion", technology: ".Net", category: "net", link: "https://greenonion.pro/" },
    // { id: 15, image: require('../../Assets/Images/Portfolio/Laravel/barber-app.webp'), alt:"Vidhan Infotech Barber App", cap:"Vidhan Infotech Barber App", name: "Barber App", technology: "Laravel + React Js", category: "laravel", link: "http://barberapp.vidhaninfotech.com" },
    // { id: 16, image: require('../../Assets/Images/Portfolio/Laravel/job-city.webp'), name: "Job City Portal", technology: "Laravel", category: "laravel", link: "http://jobcity.vidhaninfotech.com/" },
    // { id: 17, image: require('../../Assets/Images/Portfolio/Laravel/Inventory-pharmacy.webp'), alt:"Vidhan Infotech Inventory Pharmacy", cap:"Vidhan Infotech Inventory Pharmacy", name: "Inventory Pharmacy", technology: "Laravel", category: "laravel", link: "skype:live:.cid.8f1bea8854ffd1c?chat" },
    // { id: 18, image: require('../../Assets/Images/Portfolio/Laravel/oz.webp'), name: "OZ Development", technology: "Laravel", category: "laravel", link: "http://13.228.90.33/" },
    // { id: 19, image: require('../../Assets/Images/Portfolio/Laravel/posadmin.webp'), alt:"Vidhan Infotech Pos Admin", cap:"Vidhan Infotech Pos Admin", name: "Pos Admin", technology: "Laravel", category: "laravel", link: "skype:live:.cid.8f1bea8854ffd1c?chat" },
    {
      id: 20,
      image: require("../../Assets/Images/Portfolio/ReactNative/emp_app-1.webp"),
      alt: "Vidhan Infotech Employee Time Tracking",
      cap: "Vidhan Infotech Employee Time Tracking",
      class: "wl_50",
      name: "Employee Time Tracking",
      technology: "React Native",
      category: "mobile",
      link:
        "https://play.google.com/store/apps/details?id=com.rashays.myconnectmobile",
    },
    {
      id: 21,
      image: require("../../Assets/Images/Portfolio/ReactNative/barber-app.webp"),
      alt: "Vidhan Infotech Barber App",
      cap: "Vidhan Infotech Barber App",
      class: "wl_50",
      name: "Barber App",
      technology: "React Native",
      category: "mobile",
      link: "skype:live:.cid.8f1bea8854ffd1c?chat",
    },
    {
      id: 22,
      image: require("../../Assets/Images/Portfolio/ReactNative/datingapp.webp"),
      alt: "Vidhan Infotech Dating App",
      cap: "Vidhan Infotech Dating App",
      class: "wl_50",
      name: "Dating App",
      technology: "React Native",
      category: "mobile",
      link: "skype:live:.cid.8f1bea8854ffd1c?chat",
    },
    {
      id: 23,
      image: require("../../Assets/Images/Portfolio/ReactNative/online-food-deliver.webp"),
      alt: "Vidhan Infotech Online Food Odering Admin",
      cap: "Vidhan Infotech Online Food Odering Admin",
      class: "wl_50",
      name: "Online Food Order",
      technology: "React Native",
      category: "mobile",
      link: "https://play.google.com/store/apps/details?id=order.rashays.app",
    },
    {
      id: 24,
      image: require("../../Assets/Images/Portfolio/ReactNative/instant-grocery.webp"),
      alt: "Vidhan Infotech Grocery App",
      cap: "Vidhan Infotech Grocery App",
      class: "wl_50",
      name: "Grocery App",
      technology: "React Native",
      category: "mobile",
      link: "skype:live:.cid.8f1bea8854ffd1c?chat",
    },
    {
      id: 24,
      image: require("../../Assets/Images/Portfolio/ReactNative/cashcow.webp"),
      alt: "Vidhan Infotech Cash Cow App",
      cap: "Vidhan Infotech Cash Cow App",
      class: "wl_50",
      name: "Cash Cow",
      technology: "Flutter",
      category: "mobile",
      link: "skype:live:.cid.8f1bea8854ffd1c?chat",
    },
    {
      id: 26,
      image: require("../../Assets/Images/Portfolio/ReactNative/swanmobile.webp"),
      alt: "Vidhan Infotech Swan Scoring App",
      cap: "Vidhan Infotech Swan Scoring App",
      class: "wl_50",
      name: "Swan Scoring",
      technology: "React Native",
      category: "mobile",
      link:
        "https://play.google.com/store/apps/details?id=com.swanscoring.swan",
    },
    {
      id: 27,
      image: require("../../Assets/Images/Portfolio/html/prarns.webp"),
      alt: "Vidhan Infotech Prans impex",
      cap: "Vidhan Infotech Prans impex",
      class: "wl_50",
      name: "Prans impex",
      technology: "HTML + CSS",
      category: "html",
      link: "skype:live:.cid.8f1bea8854ffd1c?chat",
    },
    {
      id: 28,
      image: require("../../Assets/Images/Portfolio/React/fig.jpg"),
      alt: "Vidhan Infotech The Fig",
      cap: "Vidhan Infotech The Fig",
      class: "wl_50",
      name: "The Fig",
      technology: "React Js",
      category: "react",
      link: "https://figk-12.com",
    },
    {
      id: 29,
      image: require("../../Assets/Images/Portfolio/React/green.jpg"),
      alt: "Vidhan Infotech Green Onion",
      cap: "Vidhan Infotech Green Onion",
      class: "wl_50",
      name: "Green Onion",
      technology: "React Js + .Net",
      category: "react",
      link: "http://lifetimek12.com/",
    },
    {
      id: 30,
      image: require("../../Assets/Images/Portfolio/React/eventplanner.jpg"),
      alt: "Vidhan Infotech Conference Planner",
      cap: "Vidhan Infotech Conference Planner",
      class: "wl_50",
      name: "Conference Planner",
      technology: "React Js + .Net",
      category: "react",
      link: "https://fig.events/",
    },
    {
      id: 31,
      image: require("../../Assets/Images/Portfolio/ReactNative/fig_app.png"),
      alt: "Vidhan Infotech The Fig",
      cap: "Vidhan Infotech The Fig",
      class: "wl_50",
      name: " The Fig",
      technology: "React Native",
      category: "mobile",
      link: "https://play.google.com/store/apps/details?id=com.figk12.app",
    },
    {
      id: 32,
      image: require("../../Assets/Images/Portfolio/Net/camping.jpg"),
      alt: "Vidhan Infotech Campaing",
      cap: "Vidhan Infotech Campaing",
      class: "wl_50",
      name: " Campaing",
      technology: ".Net",
      category: "net",
      link: "http://campaign.vidhaninfotech.com/Login/DoLogin",
    },
  ];
  const [item, setItem] = useState(gallerydata);
  const [activButton, setActivButton] = useState("");

  const Filteritem = (filtItem) => {
    const filtereditem = gallerydata.filter((Curritem) => {
      return Curritem.category === filtItem;
    });
    setItem(filtereditem);
    setActivButton(filtItem);
  };
  const Filterall = () => {
    setItem(gallerydata);
    setActivButton("");
  };

  return (
    <>
      <NavigationBar />
      <section className="work">
        <div className="container">
          <div className="title mb-5">
            {/* <h4 className="line_title">Services</h4> */}
            <h1>
              Our <span>Amazing Works</span>
            </h1>
            <p>
              We offer a wide range of software development services that
              provide industry specific solutions that align people, processes
              and systems to scale and grow your business to pro level.
            </p>
          </div>
          <Uiux />
        </div>
      </section>
      <section className="portfolio">
        <div className="container">
          <div className="row mb-4">
            <div className="col-lg-12">
              <div className="title mb-5">
                <h4 className="line_title">Portfolio</h4>
                <h1>
                  Our <span>Portfolio</span>
                </h1>
                <p>
                  We offer a wide range of software development services that
                  provide industry specific solutions that align people,
                  processes and systems to scale and grow your business to pro
                  level.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="portfolio_filter_button">
              <button
                className={activButton === "" ? "active" : ""}
                onClick={() => Filterall()}
              >
                All
              </button>
              <button
                className={activButton === "react" ? "active" : ""}
                onClick={() => Filteritem("react")}
              >
                React Js
              </button>
              <button
                className={activButton === "angular" ? "active" : ""}
                onClick={() => Filteritem("angular")}
              >
                Angular
              </button>
              <button
                className={activButton === "net" ? "active" : ""}
                onClick={() => Filteritem("net")}
              >
                .Net
              </button>
              {/* <button className={activButton === "laravel" ? "active" : ""} onClick={() => Filteritem('laravel')}>Laravel</button> */}
              {/* <button className={activButton === "python" ? "active" : ""} onClick={() => Filteritem('python')}>Python</button> */}
              <button
                className={activButton === "mobile" ? "active" : ""}
                onClick={() => Filteritem("mobile")}
              >
                Mobile App
              </button>
              <button
                className={activButton === "html" ? "active" : ""}
                onClick={() => Filteritem("html")}
              >
                HTML/CSS
              </button>
            </div>
            <div className="portfolio_row">
              {item.map((Projects, i) => (
                <PortfolioBox
                  key={i}
                  className={Projects.class}
                  Image={Projects.image}
                  alt={Projects.alt}
                  cap={Projects.cap}
                  Name={Projects.name}
                  Technology={Projects.technology}
                  Link={Projects.link}
                />
              ))}
            </div>
          </div>
        </div>
      </section>
      <NewFooter />
    </>
  );
}
export default React.memo(Portfolio);
