import React from "react";
import "./Services.scss";
import { Link } from "react-router-dom";
import NavigationBar from "../Shared/NavigationBar";
import NewFooter from "../Shared/NewFooter";
import dot from "../../Assets/Red_Theme/dot.svg";
import aboutPageImg from "../../Assets/Red_Theme/serviceBanner.jpg";
import rightArrow from "../../Assets/Red_Theme/rightArrow.svg";
import uiux_banner from "../../Assets/Red_Theme/services/uiux_banner.svg";
import uiux_banner1 from "../../Assets/Red_Theme/services/uiux_banner1.svg";
import appDevelopment_banner1 from "../../Assets/Red_Theme/services/appDevelopment_banner1.svg";
import appDevelopment_banner from "../../Assets/Red_Theme/services/appDevelopment_banner.svg";
import softwareDevelopment_banner1 from "../../Assets/Red_Theme/services/softwareDevelopment_banner1.svg";
import softwareDevelopment_banner from "../../Assets/Red_Theme/services/softwareDevelopment_banner.svg";
import aiArtificial_banner1 from "../../Assets/Red_Theme/services/aiArtificial_banner1.svg";
import aiArtificial_banner from "../../Assets/Red_Theme/services/aiArtificial_banner.svg";
import quality_banner from "../../Assets/Red_Theme/services/quality_banner.svg";
import quality_banner1 from "../../Assets/Red_Theme/services/quality_banner1.svg";
import boxPettern from "../../Assets/Red_Theme/services/boxPettern.svg";
import Technologies from "../Home/InnerSections/Technologies";
import NewServicesBox from "../../Components/NewServicesBox";
import "react-multi-carousel/lib/styles.css";
import barber from "../../Assets/Red_Theme/ourClient/barber.png";
import desice from "../../Assets/Red_Theme/ourClient/desice.png";
import lalas from "../../Assets/Red_Theme/ourClient/lalas.png";
import fig from "../../Assets/Red_Theme/ourClient/fig.png";
import naweday from "../../Assets/Red_Theme/ourClient/naweday.png";

const Banner = ({ images, speed }) => {
  return (
    <div className="inner">
      <div className="wrapper">
        <section className="slide" style={{ "--speed": `${speed}ms` }}>
          {images.map(({ id, image }) => (
            <div className="image" key={id}>
              <img src={image} alt={id} />
            </div>
          ))}
        </section>
        <section className="slide" style={{ "--speed": `${speed}ms` }}>
          {images.map(({ id, image }) => (
            <div className="image" key={id}>
              <img src={image} alt={id} />
            </div>
          ))}
        </section>
        <section className="slide" style={{ "--speed": `${speed}ms` }}>
          {images.map(({ id, image }) => (
            <div className="image" key={id}>
              <img src={image} alt={id} />
            </div>
          ))}
        </section>
      </div>
    </div>
  );
};

const images = [fig, barber, desice, lalas, naweday].map((image) => ({
  id: generateId(16),
  image,
}));

function generateId(len) {
  var arr = new Uint8Array((len || 40) / 2);
  window.crypto.getRandomValues(arr);
  return Array.from(arr, dec2hex).join("");
}
function dec2hex(dec) {
  return dec.toString(16).padStart(2, "0");
}

function Services() {
  const servicesData = [
    {
      id: 1,
      countNumber: "01",
      title: "UI/UX",
      spanintitle: "Designs",
      decscription:
        "we offer expert UI/UX design services tailored to meet the evolving needs of modern businesses and their users. Our dedicated team of designers combines creativity with technical expertise to craft intuitive and visually stunning digital experiences that engage and delight audiences across various platforms and devices",
      banner: uiux_banner,
      banner1: uiux_banner1,
      points: [
        {
          text: "User-Centric Approach",
        },
        {
          text: "Research and Analysis",
        },
        {
          text: "Intuitive Design",
        },
        {
          text: "Visual Design",
        },
        {
          text: "Prototyping and Iteration",
        },
        {
          text: "Responsive and Adaptive Design",
        },
        {
          text: "Accessibility",
        },
        {
          text: "Client Satisfaction",
        },
      ],
    },
    {
      id: 2,
      countNumber: "02",
      title: "App",
      spanintitle: "Development",
      decscription:
        "Our app development services encompass the entire lifecycle of mobile app creation, from concept to deployment and beyond. Whether you're looking to build a native iOS app, an Android application, or a cross-platform solution, our team of experienced developers has the skills and expertise to bring your vision to life.",
      banner: appDevelopment_banner,
      banner1: appDevelopment_banner1,
      points: [
        {
          text: "Customized Solutions",
        },
        {
          text: "Platform Expertise",
        },
        {
          text: "Full Lifecycle Services",
        },
        {
          text: "Cutting-Edge Technologies",
        },
        {
          text: "Agile Methodology",
        },
        {
          text: "Quality Assurance",
        },
        {
          text: "Security and Compliance",
        },
        {
          text: "Post-Launch Support",
        },
      ],
    },
    {
      id: 3,
      countNumber: "03",
      title: "Software",
      spanintitle: "Development",
      decscription:
        "At Vidhan Infotech, we understand that every business is unique, with its own set of challenges and opportunities. That's why we take a personalized approach to software development, working closely with our clients to understand their specific needs, goals, and requirements.",
      banner: softwareDevelopment_banner,
      banner1: softwareDevelopment_banner1,
      points: [
        {
          text: "Custom Software Solutions",
        },
        {
          text: "Full-Stack Development",
        },
        {
          text: "Agile Methodology",
        },
        {
          text: "User-Centric Design",
        },
        {
          text: "Scalability and Performance",
        },
        {
          text: "Security and Compliance",
        },
        {
          text: "Documentation and Training",
        },
        {
          text: "Post-Implementation Support",
        },
      ],
    },
    {
      id: 4,
      countNumber: "04",
      title: "AI -Artificial",
      spanintitle: "Intelligence",
      decscription:
        "Unlock the power of artificial intelligence with Vidhan Infotech. We offer comprehensive AI services designed to help businesses harness the transformative potential of AI technologies and drive innovation in their operations.",
      banner: aiArtificial_banner,
      banner1: aiArtificial_banner1,
      points: [
        {
          text: "Custom AI Solutions",
        },
        {
          text: "Expertise in AI Technologies",
        },
        {
          text: "Data-Driven Approach",
        },
        {
          text: "Problem-Solving Capabilities",
        },
        {
          text: "Scalability and Performance",
        },
        {
          text: "Interdisciplinary Expertise",
        },
        {
          text: "Ethical AI Practices",
        },
        {
          text: "Integration and Deployment",
        },
      ],
    },
    {
      id: 5,
      countNumber: "05",
      title: "Ecommerce",
      spanintitle: "Services",
      decscription:
        "we offer expert UI/UX design services tailored to meet the evolving needs of modern businesses and their users. Our dedicated team of designers combines creativity with technical expertise to craft intuitive and visually stunning digital experiences that engage and delight audiences across various platforms and devices.",
      banner: uiux_banner,
      banner1: uiux_banner1,
      points: [
        {
          text: "User-Centric Approach",
        },
        {
          text: "Research and Analysis",
        },
        {
          text: "Intuitive Design",
        },
        {
          text: "Visual Design",
        },
        {
          text: "Prototyping and Iteration",
        },
        {
          text: "Responsive and Adaptive Design",
        },
        {
          text: "Accessibility",
        },
        {
          text: "Client Satisfaction",
        },
      ],
    },
    {
      id: 6,
      countNumber: "06",
      title: "Quality",
      spanintitle: "Assurance",
      decscription:
        "Our app development services encompass the entire lifecycle of mobile app creation, from concept to deployment and beyond. Whether you're looking to build a native iOS app, an Android application, or a cross-platform solution, our team of experienced developers has the skills and expertise to bring your vision to life.",
      banner: quality_banner,
      banner1: quality_banner1,
      points: [
        {
          text: "Customized Solutions",
        },
        {
          text: "Platform Expertise",
        },
        {
          text: "Full Lifecycle Services",
        },
        {
          text: "Cutting-Edge Technologies",
        },
        {
          text: "Agile Methodology",
        },
        {
          text: "Quality Assurance",
        },
        {
          text: "Security and Compliance",
        },
        {
          text: "Post-Launch Support",
        },
      ],
    },
  ];
  return (
    <>
      <NavigationBar />
      <section className="servicepage">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="left">
                <h1>
                  We craft indelible digital experience via best{" "}
                  <span>services.</span>
                  <div className="sideSubTxt">Services</div>
                </h1>
                <p>
                  We offer a wide range of software development services that
                  provide industry specific solutions that align people,
                  processes and systems to scale and grow your business to pro
                  level.
                </p>
              </div>
            </div>
            <div className="col-md-6 right">
              <img src={aboutPageImg} alt="" />
            </div>
          </div>
        </div>
        <img src={dot} className="dot" alt="" />
      </section>
      <section className="servicePageClient">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-md-4 left">
              <p>Trusted By 80+ Happy Clients, Including Fortune Companies</p>
            </div>
            <div className="col-md-8">
              <div className="carousel_card">
                <Banner images={images} speed={4500} />
              </div>
            </div>
          </div>
        </div>
      </section>
      <NewServicesBox data={servicesData[0]} direction="left" />
      <NewServicesBox data={servicesData[1]} direction="right" />
      <section className="scheduleCall">
        <div className="container">
          <div className="col-md-12">
            <div className="box">
              <img src={boxPettern} className="boxPettern" alt="" />
              <h1>
                Ready to Transform Your Concept into a Winning{" "}
                <span>Product?</span>
              </h1>
              <p>
                Understanding your expectation to build a world-class digital
                product, our team of software engineers aim to turn your concept
                into a market-ready product.
              </p>
              <button className="theme_btn">
                <span>Schedule A Call</span>
              </button>
            </div>
          </div>
        </div>
      </section>
      <NewServicesBox data={servicesData[2]} direction="left" />
      <NewServicesBox data={servicesData[3]} direction="right" />
      <section className="hiringModels">
        <div className="container">
          <div className="title">
            <h4 className="line_title">Hiring Models</h4>
            <h1>
              Our Committed <span>Hiring Models</span>
            </h1>
            <p>
              Employ qualified individuals from Vidhan Infotech, and they will
              work diligently as an extension of your remote team, responsibly
              addressing your range of digital product development needs.
            </p>
          </div>
          <div className="row">
            <div className="col-md-6">
              <div className="modalBox">
                <h4>Boost the Performance of Your Present Group</h4>
                <p>
                  Choose this model when you only need to hire a specific skill
                  set from our vast network to go beyond the traditional hiring
                  approach.
                </p>
                <Link
                  to="https://calendly.com/vidhaninfotech/project-discussion?month=2024-06"
                  target="_blank"
                  className="theme_btn"
                >
                  <span>
                    Schedule a Call <img src={rightArrow} alt="" />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-md-6">
              <div className="modalBox">
                <h4>Boost the Performance of Your Present Group</h4>
                <p>
                  Choose this model when you only need to hire a specific skill
                  set from our vast network to go beyond the traditional hiring
                  approach.
                </p>
                <Link
                  to="https://calendly.com/vidhaninfotech/project-discussion?month=2024-06"
                  target="_blank"
                  className="theme_btn"
                >
                  <span>
                    Schedule a Call <img src={rightArrow} alt="" />
                  </span>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NewServicesBox data={servicesData[4]} direction="left" />
      <NewServicesBox data={servicesData[5]} direction="left" />
      <Technologies />
      <NewFooter />
    </>
  );
}
export default React.memo(Services);
