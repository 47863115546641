import React from "react";
import "./NewServicesBox.scss";
import { Link } from "react-router-dom";
import rightArrow from "../Assets/Red_Theme/rightArrow.svg";
import uiux_vector from "../Assets/Red_Theme/services/uiux_vector.svg";
import appDevelopment_vector from "../Assets/Red_Theme/services/appDevelopment_vector.svg";

function ServicesBox(props) {
  return (
    <>
      {props.direction === "left" ? (
        <section className="service_uiuxDesign">
          <div className="container">
            <div className="row">
              <div className="col-md-5">
                <div className="leftSide">
                  <div className="titleName">
                    <span className="countNumber">
                      {props?.data?.countNumber}
                    </span>
                    <p>
                      {props?.data?.title}{" "}
                      <span>{props?.data?.spanintitle}</span>
                    </p>
                  </div>
                  <img className="uiux_vector" src={uiux_vector} alt="" />
                  <Link
                    to={{ pathname: props?.data?.to }}
                    className="theme_btn"
                  >
                    <span>
                      Read More <img src={rightArrow} alt="" />
                    </span>
                  </Link>
                </div>
              </div>
              <div className="col-md-7">
                <div className="rightSide">
                  <p>{props?.data?.decscription}</p>
                  <ul>
                    {props?.data?.points?.map((item, i) => (
                      <li key={i}>{item?.text}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-md-12">
                <div className="banner">
                  <img src={props?.data?.banner} alt="" />
                  <img
                    className="uiux_banner1"
                    src={props?.data?.banner1}
                    alt=""
                  />
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="service_appDevelopment">
          <div className="container">
            <div className="row">
              <div className="col-md-7">
                <div className="rightSide">
                  <p>{props?.data?.decscription}</p>
                  <ul>
                    {props?.data?.points?.map((item, i) => (
                      <li key={i}>{item?.text}</li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="col-md-5">
                <div className="leftSide">
                  <div className="titleName">
                    <p>
                      {props?.data?.title}{" "}
                      <span>{props?.data?.spanintitle}</span>
                    </p>
                    <span className="countNumber">
                      {props?.data?.countNumber}
                    </span>
                  </div>
                  <img
                    className="uiux_vector"
                    src={appDevelopment_vector}
                    alt=""
                  />
                  <Link
                    to={{ pathname: props?.data?.to }}
                    className="theme_btn"
                  >
                    <span>
                      Read More <img src={rightArrow} alt="" />
                    </span>
                  </Link>
                </div>
              </div>
              <div className="col-md-12">
                <div className="banner">
                  <img
                    className="uiux_banner1"
                    src={props?.data?.banner1}
                    alt=""
                  />
                  <img src={props?.data?.banner} alt="" />
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
}
export default React.memo(ServicesBox);
