import React, { useState } from "react";
import { useParams } from "react-router-dom";
import NavigationBar from "../Shared/NavigationBar";
import NewFooter from "../Shared/NewFooter";
import "./blog.scss";
import QualityAssuranceDevelopment from "./Components/Quality-assurance-in-app-development";
import HowToPickTheBestWebAppTechnology from "./Components/How-to-pick-the-best-web-app-technology";
import TopMorketingStratagey from "./Components/TopMorketingStratagey";
import KnowHowToDesignAWebsite from "./Components/Know-How-To-Design-A-Website";
import TheBestKeyFeaturesOfTopERPSystemsForBusiness from "./Components/The-Best-Key-Features-Of-Top-ERP-Systems-For-Business";
import The7BestFreeWebsiteBuildersIn2022 from "./Components/The-7-Best-Free-Website-Builders-In-2022";
import HowToStartAnEcommerceBusinessTheBestGuideFor2022Look from "./Components/How-To-Start-An-Ecommerce-Business-The-Best-Guide-For-2022-Look";
import FiveMostPopularWebAppFrameworksNeedIn2022 from "./Components/5-Most-Popular-Web-App-Frameworks-Need-In-2022";
import TheERPImplementationProcessUsefulIn5StagesWatch from "./Components/The-ERP-Implementation-Process-Useful-In-5-Stages-Watch";
import InterestingFactsMoreAboutSoftwareDevelopmentResearch from "./Components/10-Interesting-Facts-More-About-Software-Development-Research";
import TheBest5ReasonstochooseReactJSforDevelopment from "./Components/The-Best-5-Reasons-to-choose-ReactJS-for-Development";
import HereTheUltimateGuideonBankingSoftwareDevelopment from "./Components/Here-The-Ultimate-Guide-on-Banking-Software-Development";
import UltimateTheBestECommerceServiceProvidersForHighGrowthBrandsNow from "./Components/Ultimate-The-Best E-Commerce-Service-Providers-for-High-Growth-Brands-Now";

function Viewblogs() {
  let { id } = useParams();
  const [activeBlog] = useState(id);

  return (
    <>
      <NavigationBar />
      {activeBlog ===
      "quality-assurance-in-app-development-think-about-business" ? (
        <QualityAssuranceDevelopment />
      ) : activeBlog === "how-to-pick-the-best-web-app-technology-in-2022" ? (
        <HowToPickTheBestWebAppTechnology />
      ) : activeBlog ===
        "the-ultimate-top-5-e-commerce-marketing-strategy-for-best-success" ? (
        <TopMorketingStratagey />
      ) : activeBlog ===
        "step-by-step-guide-know-how-to-design-a-website-in-2022" ? (
        <KnowHowToDesignAWebsite />
      ) : activeBlog ===
        "the-best-key-features-of-top-erp-systems-for-business" ? (
        <TheBestKeyFeaturesOfTopERPSystemsForBusiness />
      ) : activeBlog === "the-7-best-free-website-builders-in-2022" ? (
        <The7BestFreeWebsiteBuildersIn2022 />
      ) : activeBlog ===
        "how-to-start-an-ecommerce-business-the-best-guide-for-2022-look" ? (
        <HowToStartAnEcommerceBusinessTheBestGuideFor2022Look />
      ) : activeBlog ===
        "10-interesting-facts-more-about-software-development-research" ? (
        <InterestingFactsMoreAboutSoftwareDevelopmentResearch />
      ) : activeBlog === "5-most-popular-web-app-frameworks-need-in-2022" ? (
        <FiveMostPopularWebAppFrameworksNeedIn2022 />
      ) : activeBlog ===
        "the-erp-implementation-process-useful-in-5-stages-watch" ? (
        <TheERPImplementationProcessUsefulIn5StagesWatch />
      ) : activeBlog ===
        "the-best-5-reasons-to-choose-reactjs-for-development" ? (
        <TheBest5ReasonstochooseReactJSforDevelopment />
      ) : activeBlog ===
        "here-the-ultimate-guide-on-banking-software-development" ? (
        <HereTheUltimateGuideonBankingSoftwareDevelopment />
      ) : activeBlog ===
        "ultimate-the-best-eCommerce-service-providers-for-high-growth-brands-now" ? (
        <UltimateTheBestECommerceServiceProvidersForHighGrowthBrandsNow />
      ) : (
        ""
      )}
      <NewFooter />
    </>
  );
}
export default React.memo(Viewblogs);
