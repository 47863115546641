import React, { Suspense, lazy } from "react";
import './Home.scss';
import NavigationBar from "../Shared/NavigationBar";
import NewFooter from "../Shared/NewFooter";
const TopBanner = lazy(() => import("./InnerSections/TopBanner"));
const OurClients = lazy(() => import("./InnerSections/OurClients"));
const AboutUs = lazy(() => import("./InnerSections/AboutUs"));
const Culture = lazy(() => import("./InnerSections/Culture"));
const WhatWeDoNew = lazy(() => import("./InnerSections/WhatWeDoNew"));
const HowWeEngage = lazy(() => import("./InnerSections/HowWeEngage"));
const Industry = lazy(() => import("./InnerSections/Industry"));
const ServicesNew = lazy(() => import("./InnerSections/ServicesNew"));
const Technologies = lazy(() => import("./InnerSections/Technologies"));
const Process = lazy(() => import("./InnerSections/Process"));
const Blog = lazy(() => import("./InnerSections/Blog"));
const GetinTouch = lazy(() => import("./InnerSections/GetinTouch"));

const renderLoader = () => <p></p>;

function Home() {
  return (
    <Suspense fallback={renderLoader()}>
      <NavigationBar />
      <TopBanner />
      <OurClients />
      <AboutUs />
      <Culture />
      <WhatWeDoNew />
      <HowWeEngage />
      <Industry />
      <ServicesNew />
      <Technologies />
      <Process />
      <Blog />
      <GetinTouch />
      <NewFooter />
    </Suspense>
  );
}
export default React.memo(Home);
