import React, { useState, useEffect } from 'react'
import DocumentMeta from 'react-document-meta';
import { useLocation } from 'react-router-dom'


function Meta() {
    const [meta, setMeta] = useState({});
    const location = useLocation();
    const { pathname } = location;
    const CurrentLocation = pathname.split("/");
    const path = CurrentLocation[1];
    const childPath = CurrentLocation[2];
    const metaData = [
        {
            url: 'home',
            metaInfo: {
                title: 'India & USA Based IT Service Provider | Vidhan Infotech Pvt. Ltd.',
                description: 'Vidhan Infotech is a top web, mobile app and software development agency in India & USA, We provide IT technology services & solutions to startups, enterprises.',
                canonical: 'https://www.vidhaninfotech.com/home',
                meta: {
                    name: { keywords: 'vidhan infotech pvt. ltd., vidhan infotech in ahmedabad, software development company in gujarat, custom software development company in ahmedabad, website development company in ahmedabad, website development in ahmedabad, best website designer in ahmedabad' },
                }
            }
        },
        {
            url: 'about',
            metaInfo: {
                title: 'About vidhan infotech | website development in ahmedabad',
                description: 'Vidhan Infotech is an exponentially rising web and app development. We provide services in Android, iOS, Laravel, asp.net, Angular, and ReactJS over the Globe.',
                canonical: 'https://www.vidhaninfotech.com/about',
                meta: {
                    name: { keywords: 'about vidhan infotech, about vidhan development, about vidhan infotech services' },
                }
            }
        },
        {
            url: 'services',
            metaInfo: {
                title: 'Best Web Maintenance Services at Vidhan Infotech',
                description: 'We offer Best Web Maintenance Services, We also specialize in web and app development, digital marketing services, and much more.',
                canonical: 'https://www.vidhaninfotech.com/services',
                meta: {
                    name: { keywords: 'Best Web Development Services, Best Web Maintenance Services, best web development company, top web design company, development company, best online marketing service' },
                }
            }
        },
        {
            url: 'portfolio',
            metaInfo: {
                title: 'Professional Development Portfolio | Vidhan Infotech',
                description: 'Get a closer look at our recent web and app development portfolio. Our highly skilled developers and designers have great mastery in the web & mobile apps.',
                canonical: 'https://www.vidhaninfotech.com/portfolio',
                meta: {
                    name: { keywords: 'web and app development portfolio, professional development portfolio, front end developer portfolio projects, angular portfolio, portfolio website using react js' },
                }
            }
        },
        {
            url: 'blog',
            metaInfo: {
                title: 'Our Blogs | Software Development in Ahmedabad',
                description: 'To assist you in keeping track of the data you require, look for new trends in web development and digital trade by reading the most recent blogs in this field.',
                canonical: 'https://www.vidhaninfotech.com/blog',
                meta: {
                    name: { keywords: 'digital marketing agency in ahmedabad, web design company in ahmedabad, content marketing agency in ahmedabad, software development in ahmedabad, seo company in ahmedabad, our blogs' },
                }
            }
        },
        {
            url: 'career',
            metaInfo: {
                title: 'Job Openings at Vidhan Infotech | Web and App Development',
                description: 'We are always looking for expert developers! We hire experienced candidates in web design, web development, mobile app development, SEO and digital marketing.',
                canonical: 'https://www.vidhaninfotech.com/career',
                meta: {
                    name: { keywords: 'job openings, career at vidhan infotech, job openings at vidhan infotech, career opportunities' },
                }
            }
        },
        {
            url: 'application-development',
            metaInfo: {
                title: 'Application Development | Vidhan Infotech Pvt. Ltd.',
                description: 'Our trustworthy development teams are experts in a wide range of technology stacks. We specialize in cutting-edge app development and innovative designs.',
                canonical: 'https://www.vidhaninfotech.com/viewservices/application-development',
                meta: {
                    name: { keywords: 'software development services, app development services, android mobile app development, iOS app development, Hybrid app development' },
                }
            }
        },
        {
            url: 'custom-website-design',
            metaInfo: {
                title: 'Custom Web Design | Vidhan Infotech Pvt. Ltd.',
                description: 'Vidhan Infotech delivers 360-degree custom web design services, from impactful enterprise software to cutting-edge technology solutions. Find out more.',
                canonical: 'https://www.vidhaninfotech.com/viewservices/custom-website-design',
                meta: {
                    name: { keywords: 'website development, custom web design, custom software development services, web design, best website design' },
                }
            }
        },
        {
            url: 'software-development',
            metaInfo: {
                title: 'Software Development | Vidhan Infotech Pvt. Ltd.    ',
                description: 'Our solutions can help your firm collaborate, convey, and track your software development lifecycle, from requirement design to build, test, and deployment.',
                canonical: 'https://www.vidhaninfotech.com/viewservices/software-development',
                meta: {
                    name: { keywords: 'custom software development, software development, software development life cycle, the software development life cycle, agile software development' },
                }
            }
        },
        {
            url: 'enterprise-resource-planning',
            metaInfo: {
                title: 'Enterprise Resource Planning | Vidhan Infotech Pvt. Ltd.',
                description: 'Reviews, comparisons, options, and pricing for enterprise resource planning (ERP) systems. The best ERP solutions are those for small and large firms alike.',
                canonical: 'https://www.vidhaninfotech.com/viewservices/enterprise-resource-planning',
                meta: {
                    name: { keywords: 'enterprise resource planning, erp system, best erp solutions, enterprise resource planning system, enterprise planning' },
                }
            }
        },
        {
            url: 'e-commerce',
            metaInfo: {
                title: 'Ecommerce Services | Vidhan Infotech Pvt. Ltd.',
                description: 'Vidhan Infotech eCommerce solutions use cutting-edge retail technology to address e-retailer challenges such as customer behavior, loyalty, and engagement.',
                canonical: 'https://www.vidhaninfotech.com/viewservices/e-commerce',
                meta: {
                    name: { keywords: 'ecommerce services, ecommerce services provider, what is ecommerce services, managed ecommerce services, ecommerce services in india' },
                }
            }
        },
        {
            url: 'quality-assurance',
            metaInfo: {
                title: 'Quality Assurance | Vidhan Infotech Pvt. Ltd.',
                description: 'Investigate software quality as a concept. Learn about the quality assurance and testing process, our best practices, and recommended strategies.',
                canonical: 'https://www.vidhaninfotech.com/viewservices/quality-assurance',
                meta: {
                    name: { keywords: 'quality assurance, software quality assurance, quality assurance tester, quality assurance process, quality assurance system' },
                }
            }
        },
        {
            url: 'quality-assurance-in-app-development-think-about-business',
            metaInfo: {
                title: 'Quality Assurance In App Development – Think About Business',
                description: 'The quality assurance process is one of the aspects of development. Vidhan Infotech will help you save money, time, and nerves. Let us watch!',
                canonical: 'https://www.vidhaninfotech.com/blog/quality-assurance-in-app-development-think-about-business',
                meta: {
                    name: { keywords: 'app development, mobile app development, moblie app development, how app development, how to app development, quality assurance, quality assurance in app development' },
                }
            }
        },
        {
            url: 'how-to-pick-the-best-web-app-technology-in-2022',
            metaInfo: {
                title: 'How to Pick the Best Web App Technology in 2022',
                description: 'Look at our list of the most recent web development trends to ensure you are making the product development steps with Vidhan Infotech.',
                canonical: 'https://www.vidhaninfotech.com/blog/how-to-pick-the-best-web-app-technology-in-2022',
                meta: {
                    name: { keywords: 'best web app, best web app framework, best web app design, web app technology, progressive web app technology, web app technology stack, mobile web app technology, what is web app technology' },
                }
            }
        },
        {
            url: 'the-ultimate-top-5-e-commerce-marketing-strategy-for-best-success',
            metaInfo: {
                title: 'The Ultimate Top 5 E-commerce Marketing Strategy For Best',
                description: 'Top 5 E-commerce Marketing Strategies: You do not need to be achieving everything, so arm yourself with the online trade strategies that give the best results.',
                canonical: 'https://www.vidhaninfotech.com/blog/the-ultimate-top-5-e-commerce-marketing-strategy-for-best-success',
                meta: {
                    name: { keywords: 'ecommerce services, ecommerce services providers, managed ecommerce services, what is ecommerce services' },
                }
            }
        },
        {
            url: 'step-by-step-guide-know-how-to-design-a-website-in-2022',
            metaInfo: {
                title: 'Step-by-Step Guide: Know How To Design A Website In 2022',
                description: 'Step-by-Step Guidance: This step-by-step tutorial will teach you how to design a website, write website copy, create website graphics, and more.',
                canonical: 'https://www.vidhaninfotech.com/blog/step-by-step-guide-know-how-to-design-a-website-in-2022',
                meta: {
                    name: { keywords: 'custom web design, website content, custom web design and development, content managemnet system' },
                }
            }
        },
        {
            url: 'the-best-key-features-of-top-erp-systems-for-business',
            metaInfo: {
                title: 'The Best Key Features Of Top ERP Systems For Business',
                description: 'ERP is a type of business management software that automates and manages various business processes by providing a system of integrated applications.',
                canonical: 'https://www.vidhaninfotech.com/blog/the-best-key-features-of-top-erp-systems-for-business',
                meta: {
                    name: { keywords: 'enterprise resource planning, enterprise resource planning system, what is enterprise resource planning, enterprise resource planning softwares' },
                }
            }
        },
        {
            url: 'the-7-best-free-website-builders-in-2022',
            metaInfo: {
                title: 'The 7 Best Free Website Builders in 2022',
                description: 'Are you looking for the best website builder for your project? A hands-on comparison of 7 popular website builder tools can be found in this post.',
                canonical: 'https://www.vidhaninfotech.com/blog/the-7-best-free-website-builders-in-2022',
                meta: {
                    name: { keywords: 'website builder, free website builders, best website builders, small business website builders, best free website builders, best website builders for small business' },
                }
            }
        },
        {
            url: 'how-to-start-an-ecommerce-business-the-best-guide-for-2022-look',
            metaInfo: {
                title: 'How To Start An Ecommerce Business: The Best Guide For 2022',
                description: 'How To Start An Ecommerce Business: The Best Guide For 2022: With the help of this handy how-to guide, you can start the e-commerce business of your dreams.',
                canonical: 'https://www.vidhaninfotech.com/blog/how-to-start-an-ecommerce-business-the-best-guide-for-2022-look',
                meta: {
                    name: { keywords: 'best ecommerce platform, ecommerce business, what is an ecommerce business, how to start ecommerce business, ecommerce business planning' },
                }
            }
        },
        {
            url: '10-interesting-facts-more-about-software-development-research',
            metaInfo: {
                title: '10 Interesting Facts More About Software Development',
                description: 'How much do you know about coding and programming? Let yourself be amazed and surprised by these 10 interesting facts about software development.',
                canonical: 'https://www.vidhaninfotech.com/blog/10-interesting-facts-more-about-software-development-research',
                meta: {
                    name: { keywords: 'software development, software development test engineer, software development agile' },
                }
            }
        },
        {
            url: '5-most-popular-web-app-frameworks-need-in-2022',
            metaInfo: {
                title: '5 Most Popular Web App Frameworks - Need In 2022',
                description: 'The top five web frameworks for web development in 2022 are listed below. Angular, React, Vue.js, laravel, and other frameworks.',
                canonical: 'https://www.vidhaninfotech.com/blog/5-most-popular-web-app-frameworks-need-in-2022',
                meta: {
                    name: { keywords: 'web app framework, best web app framework, build a web app,mobile web app framework, web application development' },
                }
            }
        },
        {
            url: 'the-erp-implementation-process-useful-in-5-stages-watch',
            metaInfo: {
                title: 'The ERP Implementation Process Useful In 5 Stages - Watch',
                description: 'Learn how to set up an ERP system and make it work. Businesses can use an ERP system to track each task and ensure that deadlines are met.',
                canonical: 'https://www.vidhaninfotech.com/blog/the-erp-implementation-process-useful-in-5-stages-watch',
                meta: {
                    name: { keywords: 'erp system, erp system mean, erp system stage, erp vendor, erp implementation process' },
                }
            }
        },
        {
            url: 'the-best-5-reasons-to-choose-reactjs-for-development',
            metaInfo: {
                title: 'The Best 5 Reasons to choose ReactJS for Development',
                description: 'The development of ReactJS has recently received attention. Learn the top five reasons for ReactJS rising popularity on this blog',
                canonical: 'https://www.vidhaninfotech.com/blog/the-best-5-reasons-to-choose-reactjs-for-development',
                meta: {
                    name: { keywords: 'react js, react front end, react js framework, front-end framework' },
                }
            }
        },
        {
            url: 'here-the-ultimate-guide-on-banking-software-development',
            metaInfo: {
                title: 'Here: The Ultimate Guide on Banking Software Development',
                description: 'For beginner business owners, developing financial software might be difficult. With our Financial guide for enterprises, we have made it easier for you.',
                canonical: 'https://www.vidhaninfotech.com/here-the-ultimate-guide-on-banking-software-development',
                meta: {
                    name: { keywords: 'banking software development, software development, banking software' },
                }
            }
        },
        {
            url: 'ultimate-the-best-eCommerce-service-providers-for-high-growth-brands-now',
            metaInfo: {
                title: 'Ultimate The Best E-Commerce Service Providers for Growth',
                description: 'Learn how to choose an eCommerce platform that will support your business current and future needs. Every e-commerce platform will be assessed by 2022.',
                canonical: 'https://www.vidhaninfotech.com/ultimate-the-best-ecommerce-service-providers-for-high-growth-brands-now',
                meta: {
                    name: { keywords: 'ecommerce service, woocommerce support, ecommerce platform, e-commerce platforms, bigcommerce enterprise' },
                }
            }
        }
    ]
    const filterMeta = () => {
        if (metaData.find(({ url }) => url === path)) {
            if (metaData.find(({ url }) => url === childPath)) {
                setMeta(metaData.filter((auto) => auto.url.includes(childPath))[0].metaInfo)
            }
            else {
                setMeta(metaData.filter((auto) => auto.url.includes(path))[0].metaInfo)
            }
        }
        else {
            if (metaData.find(({ url }) => url === childPath)) {
                setMeta(metaData.filter((auto) => auto.url.includes(childPath))[0].metaInfo)
            }
            else {
                setMeta(metaData.filter((auto) => auto.url.includes('home'))[0].metaInfo)
            }
        }
    }
    useEffect(() => {
        filterMeta();
    }, []);

    return (
        <DocumentMeta {...meta} />
    )
}
export default React.memo(Meta)
