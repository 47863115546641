import React from "react";
import "./blog.scss";
import Blogbox from "./Blogbox";
import NavigationBar from "../Shared/NavigationBar";
import NewFooter from "../Shared/NewFooter";
import blogdata from "./blogdata";

const latestBlog = blogdata;

function Blog() {
  return (
    <>
      <NavigationBar />
      <section className="blog_page">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              {/* <div className="wwd_heading">
                <h2>
                  Our <span>Blogs</span>
                </h2>
                <div className="wwd_tagline">
                  Check out our vidhan infotech team's tech research blogs.
                </div>
              </div> */}
              <div className="title mb-5">
                <h4 className="line_title">Blogs</h4>
                <h1>
                  Our <span>Blogs</span>
                </h1>
                <p>
                  Check out our vidhan infotech team&apos;s tech research blogs.
                </p>
              </div>
            </div>
          </div>
          <div className="row">
            {latestBlog?.map((blog, i) => (
              <Blogbox
                key={i}
                keyid={blog.id}
                title={blog.title}
                description={blog.sortdesc}
                date={blog.date}
                category={blog.category}
                image={blog.img}
                alt={blog.alt}
                cap={blog.cap}
                to={blog.id}
              />
            ))}
          </div>
        </div>
      </section>
      <NewFooter />
    </>
  );
}
export default React.memo(Blog);
