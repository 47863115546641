import React from "react";
import "./index.scss";

function Footer() {
  return (
    <footer>
      <section className="footer-section">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="title">
                <h4 className="line_title">Builds Trust</h4>
                <h1>
                  With The Advanced <span>Solutions</span> And Innovative Search
                </h1>
              </div>
            </div>
          </div>
          {/* <div className="row footer-box">
            <div className="col-lg-3">
              <Link>
                <img src={Logo} alt="Vidhan Infotech" title="Vidhan Infotech" />
              </Link>
            </div>
            <div className="col-lg-2 footer-title">
              <h3>Quick Links</h3>
            </div>
            <div className="col-lg-4">
              <ul className="footer-link">
                <li>
                  <Link to="/">Home</Link>
                </li>
                <li>
                  <Link to="/portfolio">Portfolio</Link>
                </li>
                <li>
                  <Link to="/about">About Us</Link>
                </li>
                <li>
                  <Link to="/blog">Blog</Link>
                </li>
                <li>
                  <Link to="/services">Services</Link>
                </li>
                <li>
                  <Link to="/career">Career</Link>
                </li>
              </ul>
            </div>
            <div className="col-lg-3">
              <div className="footer-title">
                <h3>Social Network</h3>
              </div>
              <ul className="footer-social">
                <li>
                  <Link
                    to="https://www.facebook.com/vidhaninfotechpvtltd/"
                    target="_blank"
                  >
                    <img src={facebook} alt="facebook" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.linkedin.com/company/vidhan-infotech-private-limited/"
                    target="_blank"
                  >
                    <img src={linkedin} alt="linkedin" />
                  </Link>
                </li>
                <li>
                  <Link
                    to="https://www.instagram.com/vidhaninfotech/?hl=en"
                    target="_blank"
                  >
                    <img src={instagram} alt="instagram" />
                  </Link>
                </li>
              </ul>
            </div>
          </div> */}
          <div className="row new-footer-links">
            <div className="col-md-4">
              <div className="footer-title">
                <h3>Top Services</h3>
              </div>
              <ul className="footer-link">
                <li>
                  <a>Mobile App</a>
                </li>
                <li>
                  <a>Website</a>
                </li>
                <li>
                  <a>JavaScript</a>
                </li>
                <li>
                  <a>Framework</a>
                </li>
                <li>
                  <a>ECommerce</a>
                </li>
                <li>
                  <a>Blockchain</a>
                </li>
                <li>
                  <a>On Demand</a>
                </li>
              </ul>
            </div>
            <div className="col-md-4">
              <div className="footer-title">
                <h3>Technologies</h3>
              </div>
              <ul className="footer-link">
                <li>
                  <a>React Js</a>
                </li>
                <li>
                  <a>Angular Js</a>
                </li>
                <li>
                  <a>Node Js</a>
                </li>
                <li>
                  <a>Python</a>
                </li>
                <li>
                  <a>UI UX Design</a>
                </li>
                <li>
                  <a>Android</a>
                </li>
                <li>
                  <a>Apple</a>
                </li>
                <li>
                  <a>ASP.Net</a>
                </li>
              </ul>
            </div>
            {/* <div className="col-md-3">
              <div className="footer-title">
                <h3>Trending</h3>
              </div>
              <ul className="footer-link">
                <li>
                  <a>Wearable</a>
                </li>
                <li>
                  <a>MCommerce</a>
                </li>
                <li>
                  <a>EV Charger</a>
                </li>
                <li>
                  <a>Virtual Reality</a>
                </li>
                <li>
                  <a>Augmented Reality</a>
                </li>
                <li>
                  <a>Smart TV</a>
                </li>
                <li>
                  <a>Chatbot</a>
                </li>
              </ul>
            </div> */}
            <div className="col-md-4">
              <div className="footer-title">
                <h3>Industries</h3>
              </div>
              <ul className="footer-link">
                <li>
                  <a>Healthcare apps</a>
                </li>
                <li>
                  <a>On demand apps</a>
                </li>
                <li>
                  <a>Finance apps</a>
                </li>
                <li>
                  <a>Social networking apps</a>
                </li>
                <li>
                  <a>Restaurant apps</a>
                </li>
                <li>
                  <a>Entertainment apps</a>
                </li>
                <li>
                  <a>Education apps</a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="copyright-section">
          <p>© Vidhan Infotech Private Limited 2024. All Rights Reserved.</p>
        </div>
      </section>
    </footer>
  );
}
export default React.memo(Footer);
