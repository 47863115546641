import React, { lazy, Suspense } from "react";
import "react-multi-carousel/lib/styles.css";
import { Link } from "react-router-dom";
import Barberapp from "./../../Assets/Images/Portfolio/Uiux/barbar.webp";
import Datingapp from "./../../Assets/Images/Portfolio/Uiux/datingapp.webp";
import Foodorder from "./../../Assets/Images/Portfolio/Uiux/online-food-order.webp";
import FoodorderAdmin from "./../../Assets/Images/Portfolio/Uiux/online-food-odering-admin.webp";
import BarRatio from "./../../Assets/Images/Portfolio/Uiux/bar-ratio.webp";
import Ecommerce from "./../../Assets/Images/Portfolio/Uiux/ecommerce.webp";
import Primemac from "./../../Assets/Images/Portfolio/Uiux/primemac.webp";
import Smartoffice from "./../../Assets/Images/Portfolio/Uiux/smartofficemanagement.webp";
import fig from "./../../Assets/Images/Portfolio/Uiux/fig.png";

const Carousel = lazy(() => import("react-multi-carousel"));
const renderLoader = () => <p></p>;

function Uiuxbox(props) {
  return (
    <Link to="" className={`uiux_box_wrapper ${props.Class}`} id={props.id}>
      <div className="uiux_box">
        <div className="content">
          <div className="title">{props.title}</div>
          <div className="desc">{props.desc}</div>
        </div>
        <div className={`image p-${props.margin ? props.margin : ""}`}>
          <img src={props.Image} alt={props.alt} title={props.cap} />
        </div>
      </div>
    </Link>
  );
}

function Uiux() {
  const responsive = {
    // the naming can be any, depends on you.
    mobile: {
      breakpoint: { max: 540, min: 0 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 768, min: 541 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 1024, min: 769 },
      items: 1,
    },
    superLargeDesktop: {
      breakpoint: { max: 1920, min: 1025 },
      items: 1,
    },
  };

  return (
    <>
      <Suspense fallback={renderLoader()}>
        <Carousel
          responsive={responsive}
          //   ssr={true}
          additionalTransfrom={2}
          arrows={true}
          autoPlaySpeed={3000}
          centerMode={false}
          className=""
          dotListClass="custom_dot"
          draggable
          focusOnSelect={false}
          infinite
          itemClass=""
          autoPlay={true}
          keyBoardControl
          // minimumTouchDrag={80}
          renderButtonGroupOutside={false}
          renderDotsOutside={false}
          showDots={false}
          sliderClass=""
          // slidesToSlide={1}
          swipeable={false}
        >
          <Uiuxbox
            id="1"
            Class="gredient1"
            Image={Foodorder}
            alt={"Vidhan Infotech Online Food Odering"}
            cap={"Vidhan Infotech Online Food Odering"}
            title="Online Food Odering"
            desc="Easy to use centralized online order management for all kinds of restaurants."
          />
          <Uiuxbox
            id="2"
            Class="gredient2"
            Image={Barberapp}
            alt={"Vidhan Infotech Barber App"}
            cap={"Vidhan Infotech Barber App"}
            title="Barber App"
            desc="Barber Application is used for booking barber services through android application."
          />
          <Uiuxbox
            id="3"
            Class="gredient3"
            Image={Datingapp}
            alt={"Vidhan Infotech Dating App"}
            cap={"Vidhan Infotech Dating App"}
            title="Dating App   "
            desc="You can browse unlimited profiles and connect with anyone you want using dating app."
          />
          <Uiuxbox
            id="4"
            Class="gredient4"
            Image={FoodorderAdmin}
            alt={"Vidhan Infotech Online Food Odering Admin"}
            cap={"Vidhan Infotech Online Food Odering Admin"}
            title="Online Food Odering Admin"
            desc="Here you can manage products and orders, offer discounts, interact with your customers and do much more."
          />
          <Uiuxbox
            id="5"
            Class="gredient5"
            Image={BarRatio}
            alt={"Vidhan Infotech Bar Ratio"}
            cap={"Vidhan Infotech Bar Ratio"}
            title="Bar Ratio"
            desc="Inventory management is a discipline primarily about specifying the shape and placement of stocked goods"
          />
          <Uiuxbox
            id="6"
            Class="gredient6"
            Image={Ecommerce}
            alt={"Vidhan Infotech Pvt. Ltd. Ecommerce Website"}
            cap={"Vidhan Infotech Pvt. Ltd. Ecommerce Website"}
            title="E Commerce"
            desc="A website that allows people to buy and sell physical goods, services, and digital products over the internet."
          />
          <Uiuxbox
            id="7"
            Class="gredient7"
            Image={Primemac}
            alt={"Vidhan Infotech Prime Mac"}
            cap={"Vidhan Infotech Prime Mac"}
            title="Prime Mac"
            desc="Military management stystem. Deals with the affairs that a military manager controls in order that combat forces may be created and supported."
          />
          <Uiuxbox
            id="8"
            Class="gredient8"
            Image={Smartoffice}
            alt={"Vidhan Infotech Smart Office Management"}
            cap={"Vidhan Infotech Smart Office Management"}
            title="Smart Office Management"
            desc="Office management is the technique of planning, organizing, coordinating and controlling office activities."
          />
          {/* <Uiuxbox id="9" Class="gredient5" Image={PeerConncect} alt={'Vidhan Infotech Peer Connect'} cap={'Vidhan Infotech Peer Connect'} title="Peer Connect" desc="Peer conncect is a platform to ask questions and get answers." /> */}
          <Uiuxbox
            id="10"
            Class="gredient1"
            margin="0"
            Image={fig}
            alt={"Vidhan Infotech The Fig"}
            cap={"Vidhan Infotech The Fig"}
            title="The Fig"
            desc="FIG is a community made up of school nutrition professionals and stakeholders with a growth mindset."
          />
        </Carousel>
      </Suspense>
    </>
  );
}
export default React.memo(Uiux);
